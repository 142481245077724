


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BorrowerContact } from "@/models/borrower-contact.model";
import { BASE_API_URL } from "@/config";

import Autocomplete from "vuejs-auto-complete";
import { HollowDotsSpinner } from "epic-spinners";

@Component({
  components: { Autocomplete, HollowDotsSpinner }
})
export default class BorrowerContactForm extends Vue {
  public borrowerContact: any = new BorrowerContact();
  public brokerLoanList: any = "";
  public defaultTitle = "Add Borrower Contact";
  public defaultSubmitName = "Add";
  public borrowerContacts = [];
  public dotLoader = false;
  public inProcess = false;
  public borrowerName = null;
  public id = null;
  public searchList = [];
  public searchName = "";
  public allBorrowersContact = [];
  public addContactLen = 0;
  public editModeForTitle = false;

  public get computedUserId () {
    return this.$store.state.sessionObject.userId;
  }

  public get computedBrokerId () {
    if (this.$store.state.sessionObject.userInfo !== null) {
      return this.$store.state.sessionObject.userInfo.brokerId;
    }
  }

  public get isWemloProcessor () {
    return this.$store.state.sessionObject.type === 'Wemlo Processor';
  }

  public get isSuperAdmin () {
    return this.$store.state.sessionObject.type === 'Super Admin';
  }

  public get contactTypeIsTitle () {
    return this.borrowerContacts.some(e => e.type === 'Title')
  }

  public async validateAndSubmit() {
    try {
      this.editModeForTitle = false;
      let formValid = await this.$validator.validate();
      if (!formValid) {
        return;
      }
      this.inProcess = true;
      this.borrowerContact["loanTxnId"] = this.$route.query.loanTxnId;
      this.borrowerContact.addedBy = this.$userId;
      switch (this.$store.state.sessionObject.type) {
        case "Broker":
          this.borrowerContact.brokerId = this.$store.state.sessionObject.userInfo.userId;
          break;
        case "MLO":
          this.borrowerContact.brokerId = this.$store.state.sessionObject.userInfo.brokerId;
          break;
        case "Admin Staff":
          this.borrowerContact.brokerId = this.$store.state.sessionObject.userInfo.brokerId;
          break;
        default:
          this.borrowerContact.brokerId = null;
          break
      }
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/addNewContact",
        this.borrowerContact);
      if (response.status == 200) {
        this.$snotify.success("Successfully Updated");
        this.showContactDetails();
        this.clearDetails();
        this.inProcess = false;
      }
    } catch (error) {
      this.inProcess = false;
      console.log(error.response);
    }
  }

  public async showContactDetails() {
    this.dotLoader = true;
    try {
      this.$modal.show("contactDetails");
      let response = await Axios.post(
        BASE_API_URL + "common/fetchContactsWithSelectedTitles",
        {
          loanTxnId: this.$route.query.loanTxnId,
        });
      this.borrowerContacts = response.data.borrowerContacts;
      this.allBorrowersContact = response.data.borrowerContacts;
      this.searchList = response.data.borrowerContacts;
      this.borrowerName = response.data.borrowerName;
      this.addContactLen = this.borrowerContacts.length
      this.dotLoader = false;
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }

  public editBorrowerContact(detail) {
    if (detail.type === 'Title') {
      this.editModeForTitle = true;
    }
    this.$validator.reset();
    this.defaultTitle = "Update Borrower-Contact";
    this.defaultSubmitName = "Update";
    this.borrowerContact = Object.assign({}, detail);
  }

  public clearDetails() {
    this.editModeForTitle = false;
    this.defaultTitle = "Add Borrower-Contact";
    this.defaultSubmitName = "Add";
    this.borrowerContact = {};
    this.$validator.reset();
  }
  public openConfirmationModal(id) {
    this.id = id;
    this.$modal.show("confirmDeleteFileModal");
  }
  public async deleteContact(id) {
    let body = {
      id: id,
      operation: "delete"
    };
    let response = await Axios.post(
      BASE_API_URL + "wemloprocessor/deleteContact",
      body);
    if (response.status == 200) {
      this.$snotify.success("Successfully Deleted");
      this.showContactDetails();
      this.clearDetails();
      this.$modal.hide("confirmDeleteFileModal");
    }
  }
    public async searchByName() {
    try {
      if (this.searchName) {
        this.borrowerContacts = this.searchList.filter(user => {
          if (
            (user.firstName + " " + user.lastName)
              .toLowerCase()
              .includes(this.searchName.toLowerCase()) ||
            user.email
              .toLowerCase()
              .includes(this.searchName.toLowerCase())
          ) {
            return user;
          }
        });
      } else {
        this.borrowerContacts = this.allBorrowersContact;
      }
    } catch (error) {}
  }

  async mounted() {
    await this.showContactDetails();
  }
}
